import React, { Component } from 'react';
import '../styles/global.scss';
import CommunityStats from './partials/communityStats';
import CCLLeadspace from '../components/cclLeadspace';
import JourneyForm from '../components/journeyForm';
import Layout from '../components/layout';
import SEO from '../components/seo';
import CCLButton from '../components/cclButton';
import CCLExplore from '../components/cclExplore';
import LeadImage from '../images/community-leadspace.jpg';
import Story1 from '../images/stories/individual1.jpg';
import Story2 from '../images/stories/community3.jpg';
import Story3 from '../images/stories/community2.png';
import Story4 from '../images/stories/individual3.png';
import '../styles/global.scss';

import CardSlider from '../components/cardSlider';

const stories = [
  {
    image: Story1,
    title: 'Finding Room to Grow',
    cta: 'view success story',
    expanded: {
      title: 'Outdoor Lover. Social Steward. Growing Youth.',
      subtitle: 'Grow your passion. Grow your team. Grow yourself, with CCL.',
      contentTitle: '',
      link: '',
      content:
        '<p>Once on track to be a doctor, Eboni Preston’s awareness of social challenges led her to redirect her passion. At Greening Youth, she educates children and young adults about the outdoors and their environment, connecting them to food-growing programs, internships, and mentorships that fuel their own passions. Her goal: teach the next generation of environmentalists.</p><p>Eboni’s CEO nominated her for a spot in the American Express Leadership Academy, a program where CCL partners with nonprofit leaders to help unlock their full potential. Through this experience, Eboni learned how to delegate and instill in every team member a stronger sense of pride and ownership in their accomplishments. Now as a true team, Eboni and her staff can achieve their goal of teaching youth to be a part of the conversation.</p>'
    }
  },
  {
    image: Story3,
    title: 'Giving Latinas a Voice in Their Communities',
    cta: 'read full case study',
    expanded: {
      title: 'Dreamers. Leaders. Ceiling Shatterers.',
      subtitle: 'CCL-MANA partnership is giving Latinas a voice in their communities.',
      contentTitle: '',
      link: 'https://www.ccl.org/articles/client-successes/inspiring-latinas-to-become-leaders/',
      content:
        '<p>MANA de San Diego is the city’s leading Latina leadership organization, providing mentorship and scholarship programs that help Latina students fulfill their educational promise. These women are often the first in their families to pursue upward mobility. Because of this, they often lack role models and mentors to help them navigate their careers, especially given the underrepresentation of Latinas in business, civic, and government leadership.</p><p>MANA partnered with CCL on an innovative initiative designed to close the gap in representation: the Latina Success Leadership Program (LSLP). Designed around the women’s unique cultural needs, LSLP encourages Latinas to set ambitious goals, develop valuable business skills, and become business and community leaders. With the confidence gained from these half-day sessions, MANA women have gone on to ask for (and get) promotions, run for public office, earn advanced degrees, and start their own businesses. And participants form a growing network of peers they can tap for support and advice.</p>'
    }
  }
];

const stories2 = [
  {
    image: Story2,
    title: 'Shaping the Leaders of Tomorrow',
    cta: 'read full case study',
    expanded: {
      title: 'Patrons. Mentors. Dream Champions.',
      subtitle: 'CCL coaching ensures Coca-Cola Scholars Foundation keeps student dreams alive.',
      contentTitle: '',
      link: 'https://www.ccl.org/articles/client-successes/coca-cola-scholars-foundation-scholar-coaching-initiative/',
      content:
        '<p>The Coca-Cola Scholars Foundation (CCSF) supports young people as they develop into leaders who make significant impacts in the world, providing college scholarships and opportunities for personal and professional growth. CCSF maintains a robust, engaged alumni network, and from here an idea was sparked &mdash; how can the Foundation continue to support students as they transition into college and professional life?</p><p>As an answer, the Foundation partnered with CCL to create the Scholar Coaching Initiative, which adds further leadership development opportunities and personalized coaching from alumni and CCSF staff. Coaches receive valuable professional development from CCL, learning how to build a meaningful coaching framework with their students. In turn, the students benefit from regular mentorship. After a successful pilot program, CCSF has been gradually rolling out the Initiative on a larger scale, ensuring the next generation of scholars is ready to change the world.</p>'
    }
  },
  {
    image: Story4,
    title: 'Healing Through Leadership',
    cta: 'view success story',
    expanded: {
      title: 'Nurse. Advocate. Community Healer.',
      subtitle: 'CCL fuels a community advocate with effective strategies for expanding her influence.',
      contentTitle: '',
      link: '',
      content:
        '<p>Angela Gray’s West Virginia community is more than just her home &mdash; it’s her people. The public health department nursing director has always considered lifting up others in her community as part of her duty, especially amid an opioid epidemic. Seeing others suffer, Angela began working hard to reduce self-harm in the community.</p><p>Then, Angela won a grant from the Robert Wood Johnson Foundation which provided access to a 2-year CCL program. The experience, with its emphasis in empathetic listening and digging deeply into behavior, completely changed her perspective. The second year focused on a specific project through which Angela created a new harm reduction/syringe exchange program that’s already proving to be effective. For Angela, this isn’t just a job. It’s a lifetime commitment to the place and people she loves. </p>'
    }
  }
]

let cards = [
  {
    header: 'Explore the latest thinking from CCL',
    subheader: '',
    cards: [
      {
        title: 'Reimagined Leadership Strategies for Nonprofit Organizations',
        link: 'https://www.ccl.org/articles/white-papers/reimagined-leadership-strategies-for-nonprofits-the-social-sector/',
        label: 'Download White Paper'
      },
      {
        title: 'Disruptive Trends Report: Talent Reimagined 2020',
        link: 'https://www.ccl.org/articles/research-reports/talent-reimagined-disruptive-trends-2020/',
        label: 'Download Report'
      },
      {
        title: 'Inspiring Latinas to Become Leaders',
        link: 'https://www.ccl.org/articles/client-successes/inspiring-latinas-to-become-leaders/',
        label: 'Read Case Study'
      },
      {
        title: 'Intentional, Inclusive, & Interdependent: Effective Leadership in the Social Sector',
        link: 'https://www.ccl.org/webinars/intentional-inclusive-and-interdependent-creating-effective-leadership-in-the-social-sector/',
        label: 'Watch Recording'
      },
      {
        title: 'Transforming K-12 Education Through Leadership Development',
        link: 'https://www.ccl.org/articles/client-successes/lead-from-here-ravenscroft-education-client-impact/',
        label: 'Read Case Study'
      },
      {
        title: 'Partnering with Johnson & Johnson to Offer Innovation Fellowship for Nurses',
        link: 'https://www.ccl.org/articles/press-releases/ccl-partners-with-johnson-johnson-to-offer-innovation-fellowship-for-nurses/',
        label: 'Learn More'
      }
    ]
  }
];

const toggleStory = s => {
  const stories = Array.prototype.slice.call(document.querySelectorAll(".open"), 0);
  for (let index = 0; index < stories.length; index++) {
    const story = stories[index];
    story.classList.remove('open');
  }
  let openedStory = document.getElementById('story' + s);
  openedStory.classList.add('open');
  let openSection;
  if (window.innerWidth > 900) {
    openSection = document.getElementById('card' + s);
    openSection.classList.add('open');
  }
  openedStory.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

const closeStory = () => {
  const stories = Array.prototype.slice.call(document.querySelectorAll('.open'), 0);
  for (let i = 0; i < stories.length; i++) {
    const story = stories[i];
    story.classList.remove('open');
  }
};

const storyContent = (content, x) => {
  let storyLink = content.link ? true : false;
  let openHeadline = content.title.split('.');
  return (
    <div className="explore-open" id={'story' + x}>
      <div className="close-story" onClick={closeStory}>
        <span>X</span>
      </div>
      <h2>{openHeadline[0] + '.'}</h2>
      <h2>{openHeadline[1] + '.'}</h2>
      <h2>{openHeadline[2] + '.'}</h2>
      <h3>{content.subtitle}</h3>
      <span dangerouslySetInnerHTML={{ __html: content.content }}></span>
      {storyLink && (
        <a href={content.link} target="_blank">
          <CCLButton style="primary" label="Read the full case study" arrow="true" />
        </a>
      )}
    </div>
  )
}

class Community extends Component {

  render() {
    return (
      <Layout>
        <SEO
          title="Communities | Community Leadership"
          description="Community leadership transforms lives and influences change. Discover our mission to impact society by creating strong leaders. "
          meta={{
            imgAlt: 'A community figure leads a group of people in discussion about culture and community leadership during a CCL program. '
          }}
        />
        <CCLLeadspace image={LeadImage} copy="Leaders made.  Lives changed.  Communities reborn." />
        <CommunityStats />
        <div className="desktop-stories">
          <div className="stories">
            {stories.map((link, i) => (
              <span
                key={link.title}
                onClick={() => {
                  toggleStory(i);
                }}
              >
                <CCLExplore className="story" label={link.title} cardId={'card' + i} cta={link.cta} image={link.image} expandedContent={true}></CCLExplore>
              </span>
            ))}
          </div>

          <div className="openStories">
            {stories.map((story, x) => (
              <React.Fragment key={story.title}>{storyContent(story.expanded, x)}</React.Fragment>
            ))}
          </div>

          <div className="stories">
            {stories2.map((link, i) => (
              <span
                key={link.title}
                onClick={() => {
                  toggleStory(i + 2);
                }}
              >
                <CCLExplore className="story" label={link.title} cardId={'card' + Number(i + 2)} cta={link.cta} image={link.image} expandedContent={true}></CCLExplore>
              </span>
            ))}
          </div>

          <div className="openStories">
            {stories2.map((story, x) => (
              <React.Fragment key={story.title}>{storyContent(story.expanded, x + 2)}</React.Fragment>
            ))}
          </div>
        </div>

        <div className="mobile-stories">
          <div className="stories">
            <span
              onClick={() => {
                toggleStory(5);
              }}
            >
              <CCLExplore className="story" label={stories[0].title} cardId={'card' + 5} cta={stories[0].cta} image={stories[0].image} expandedContent={true}></CCLExplore>
            </span>
            {storyContent(stories[0].expanded, 5)}

            <span
              onClick={() => {
                toggleStory(6);
              }}
            >
              <CCLExplore className="story" label={stories[1].title} cardId={'card' + 6} cta={stories[1].cta} image={stories[1].image} expandedContent={true}></CCLExplore>
            </span>
            {storyContent(stories[1].expanded, 6)}

            <span
              onClick={() => {
                toggleStory(7);
              }}
            >
              <CCLExplore className="story" label={stories2[0].title} cardId={'card' + 7} cta={stories2[0].cta} image={stories2[0].image} expandedContent={true}></CCLExplore>
            </span>
            {storyContent(stories2[0].expanded, 7)}

            <span
              onClick={() => {
                toggleStory(8);
              }}
            >
              <CCLExplore className="story" label={stories2[1].title} cardId={'card' + 8} cta={stories2[1].cta} image={stories2[1].image} expandedContent={true}></CCLExplore>
            </span>
            {storyContent(stories2[1].expanded, 8)}
          </div>
        </div>

        <CardSlider {...cards} />
        <JourneyForm />
      </Layout>
    );
  }
};

export default Community;
